import withTranslation from 'next-translate/withTranslation';

import { Translate } from '@core/constant';
import { EColor, EIcon, ELinkAnimation, EPosition, ERouting, WithTranslation } from '@core/type';

import { LinkTranslatedStyledAsLink } from '../link-translated';

export const LinkToFaqPageContainer = ({ i18n: { t } }: WithTranslation) => (
  <LinkTranslatedStyledAsLink
    keyRoute={ERouting.FAQ}
    color={EColor.ACCENT}
    endIcon={EIcon.ARROW_DOWN}
    iconPosition={EPosition.RIGHT}
    iconColor={EColor.SECONDARY}
    animation={ELinkAnimation.ARROW_RIGHT}
    iconOrientation={EPosition.RIGHT}
    isIconStroke
  >
    {t('moreFaq.title')}
  </LinkTranslatedStyledAsLink>
);

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const LinkToFaqPage = withTranslation(LinkToFaqPageContainer, Translate.common.LINK);
