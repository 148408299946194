import { forwardRef } from 'react';

import { EColor } from '@core/type';

import { ButtonRadio } from './ButtonRadio';
import { ButtonRadioProps } from './interface-button';

export const ButtonRadioSmallBordered = forwardRef<HTMLInputElement, ButtonRadioProps>(
  ({ currentValue, value, name, ...restProps }, ref) => {
    const isActive = currentValue === value;

    return (
      <ButtonRadio
        wrapperProps={{
          borderColor: EColor.NEUTRAL,
          padding: '8px 17px',
        }}
        circleProps={{ display: 'none' }}
        labelWrapperProps={{
          padding: '0',
          fontSize: '14px',
          color: isActive
            ? {
                commonType: EColor.WHITE,
                intensity: EColor.R900,
              }
            : EColor.PRIMARY,
        }}
        inputBorderProps={{
          borderRadius: '10px',
          borderColor: isActive
            ? EColor.PRIMARY
            : {
                commonType: EColor.GRAY,
                intensity: EColor.A200,
              },
          backgroundColor: isActive
            ? EColor.PRIMARY
            : {
                commonType: EColor.GRAY,
                intensity: EColor.A100,
              },
        }}
        {...restProps}
        value={value}
        currentValue={currentValue}
        ref={ref}
        withBorder
        name={name}
        aria-checked={isActive}
        aria-label={`${name}.${value}`}
      />
    );
  },
);

ButtonRadioSmallBordered.displayName = 'ButtonRadioSmallBordered';
