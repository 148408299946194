import styled, { css, CSSObject } from 'styled-components';

import {
  EActionAttribute,
  EButtonVariant,
  EColor,
  EFontWeight,
  ESize,
  WithThemeProps,
} from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { Button, StyledButton } from '../button/Button';
import { InputHidden } from '../input/InputHidden';
import { Box } from '../layout/Box';
import { ButtonRadioSwitchProps } from './interface-button';

export const ButtonRadioSwitch = <T extends string>({
  options,
  value: currentValue,
  ...inputProps
}: ButtonRadioSwitchProps<T>) => (
  <Box
    height={'48px'}
    display={'flex'}
    borderRadius={'8px'}
    cursor={'pointer'}
    backgroundColor={{ commonType: EColor.GRAY, intensity: EColor.A200 }}
  >
    {options.map(({ title, value }, index) => {
      const isSelected = value === currentValue;

      return (
        <StyledLabel key={`${index}/${value}`} isActive={isSelected}>
          <Button
            size={ESize.MD}
            variant={EButtonVariant.CONTAINED}
            as={EActionAttribute.SPAN}
            fontWeight={isSelected ? undefined : EFontWeight.REGULAR}
          >
            {title}
          </Button>
          <InputHidden value={value} type={'radio'} checked={isSelected} {...inputProps} />
        </StyledLabel>
      );
    })}
  </Box>
);

const StyledLabel = styled.label(
  ({
    isActive,
    theme: {
      palette,
      typography: { fontWeight },
    },
  }: WithThemeProps & { isActive: boolean }) => {
    const { getTextColor, getBackgroundColor } = getPaletteHandlers(palette);

    const inActiveProps = {
      color: getTextColor(EColor.PRIMARY),
      backgroundColor: getBackgroundColor(EColor.TRANSPARENT),
      borderColor: getBackgroundColor(EColor.TRANSPARENT),
      fontWeight: fontWeight[EFontWeight.REGULAR],
    } as CSSObject;

    return css`
      position: relative;

      ${!isActive
        ? css`
            ${StyledButton}, ${StyledButton}:hover {
              ${inActiveProps}
            }
          `
        : ''}
    `;
  },
);
