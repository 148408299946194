import { EActionAttribute, EColor, EDateFormat, ETypographyVariant, EUnit } from '@core/type';
import { formatCurrency, formatDate } from '@core/util';

import { Box } from '../layout/Box';
import { Typography } from '../typography/Typography';
import { TooltipChartInvestmentProps } from './interface-charts';

export const TooltipChartInvestment = ({
  tooltip,
  language,
  currency,
  timeUnit,
}: TooltipChartInvestmentProps) => {
  const isPositiveProfit = tooltip.growth > 0;

  return (
    <Box boxShadow={EColor.ELEVATION_3} borderRadius="8px" overflow={'hidden'} minWidth={'120px'}>
      <Box
        backgroundColor={{ semanticType: EColor.NEUTRAL, variant: EColor.LIGHT }}
        padding={'8px'}
      >
        <Typography variant={ETypographyVariant.H6} whiteSpace={'nowrap'} textAlign={'center'}>
          {formatCurrency(language, currency, tooltip.price)}
          <Typography
            as={EActionAttribute.SPAN}
            variant={ETypographyVariant.BODY3}
            color={isPositiveProfit ? EColor.SUCCESS : EColor.DANGER}
          >
            {` (${isPositiveProfit ? '+' : ''}${tooltip.growth || 0}%)`}
          </Typography>
        </Typography>
        {Boolean(tooltip.date) && (
          <Typography
            variant={ETypographyVariant.CAPTION3}
            color={EColor.SECONDARY}
            textAlign={'center'}
          >
            {formatDate(
              new Date(tooltip.date).toString(),
              language,
              timeUnit === EUnit.HOUR ? EDateFormat.ddMMyyyyhhmm : EDateFormat.ddMMyyyy,
            )}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
