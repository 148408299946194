import { useEffect, RefObject } from 'react';

type OutsideClickEvent = MouseEvent | TouchEvent;

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  { current }: RefObject<T>,
  handler: (event: OutsideClickEvent) => void,
) => {
  useEffect(() => {
    const listener = (event: OutsideClickEvent) => {
      if (current && !current.contains(event?.target as Node)) {
        handler(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [current, handler]);
};
