import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

import { InputText } from '../../input';
import { InputTextControlProps } from './interface-form-input';

export const InputTextControl = forwardRef<HTMLInputElement, InputTextControlProps>(
  ({ name, control, ...inputProps }: InputTextControlProps, refForward) => (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <InputText
          ref={refForward}
          field={field}
          fieldState={fieldState}
          onChange={field.onChange}
          {...inputProps}
        />
      )}
    />
  ),
);

InputTextControl.displayName = 'InputTextControl';
