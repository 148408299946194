import Trans from 'next-translate/Trans';

import { Translate } from '@core/constant';
import { useContextUser } from '@core/context';
import { useRedirectAfterSignInRoute } from '@core/provider';
import {
  EActionAttribute,
  EColor,
  EDateFormat,
  EIcon,
  ERouting,
  ESize,
  ETypographyVariant,
  EUnit,
} from '@core/type';
import { formatCurrency, formatDate } from '@core/util';

import { Icon } from '../icon/Icon';
import { Box } from '../layout/Box';
import { LinkTranslatedStyledAsLink } from '../link-translated/LinkTranslatedStyledAsLink';
import { Typography } from '../typography/Typography';
import { TooltipChartProps } from './interface-charts';

export const TooltipChart = ({
  tooltip,
  language,
  currency,
  metalIso,
  timeUnit,
}: TooltipChartProps) => {
  // TODO: a component should not know how to figure out if a user is logged-in or not
  const { isLogged } = useContextUser();
  // TODO: a component should not know details about routing
  const { setRoute, setQuery } = useRedirectAfterSignInRoute();

  return (
    <Box boxShadow={EColor.ELEVATION_3} borderRadius="8px" overflow={'hidden'} minWidth={'120px'}>
      <Box backgroundColor={EColor.SECONDARY}>
        <LinkTranslatedStyledAsLink
          keyRoute={ERouting.ALERT_SETTINGS}
          query={{ amount: tooltip.priceOunce.toString(), metalIso }}
        >
          <Box
            as={EActionAttribute.SPAN}
            textDecoration={'underline'}
            display={'flex'}
            gap={'4px'}
            color={EColor.PRIMARY}
            padding={'8px'}
            onClick={() => {
              if (!isLogged) {
                setRoute(ERouting.ALERT_SETTINGS);
                setQuery({ amount: tooltip.priceOunce.toString(), metalIso });
              }
            }}
          >
            <Icon type={EIcon.ALERT_FILLED} size={ESize.XS} />
            <Typography
              as={EActionAttribute.SPAN}
              variant={ETypographyVariant.CAPTION3}
              textAlign={'center'}
            >
              <Trans
                i18nKey={`${Translate.common.WIDGET}:chart.tooltip`}
                components={{ br: <br /> }}
              />
            </Typography>
          </Box>
        </LinkTranslatedStyledAsLink>
      </Box>
      <Box
        backgroundColor={{ semanticType: EColor.NEUTRAL, variant: EColor.LIGHT }}
        padding={'8px'}
      >
        <Typography variant={ETypographyVariant.H6} textAlign="center">
          {formatCurrency(language, currency, tooltip.price)}
        </Typography>
        {Boolean(tooltip.date) && (
          <Typography
            variant={ETypographyVariant.CAPTION3}
            color={EColor.SECONDARY}
            textAlign={'center'}
          >
            {formatDate(
              new Date(tooltip.date).toString(),
              language,
              timeUnit === EUnit.HOUR ? EDateFormat.ddMMyyyyhhmm : EDateFormat.ddMMyyyy,
            )}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
