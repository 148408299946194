import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import styled, { css } from 'styled-components';

import { Translate } from '@core/constant';
import { EColor, EIcon, EPosition, ESize, WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { ButtonIcon } from '../button';
import { Box } from '../layout';
import { SliderProps } from './interface-slider';

const Dot = styled.div(
  ({
    theme: { palette },
    isVisible,
  }: {
    isVisible: boolean;
  } & WithThemeProps) => {
    const { getColor } = getPaletteHandlers(palette);

    return css`
      width: ${isVisible ? '32px' : '6px'};
      height: 6px;
      border-radius: 3px;
      background-color: ${getColor(EColor.INFO)};
      opacity: ${isVisible ? '0.8' : '0.6'};
      cursor: pointer;
      transition: background-color 0.3s ease;
      ${isVisible &&
      'animation: horizontal-scale-animation 0.5s;'} @keyframes horizontal-scale-animation {
        0% {
          transform: scaleX(0.2);
        }

        80% {
          transform: scaleX(1.25);
        }

        100% {
          transform: scaleX(1);
        }
      }
    `;
  },
);

export const SlickSlider: FC<SliderProps> = ({
  totalSteps,
  currentStep,
  onPrevious,
  onNext,
  onClick,
  withArrows = false,
  withCarousel = false,
  ...restProps
}) => {
  const { t: tAria } = useTranslation(Translate.common.BUTTON);

  const handlePrevious = () => {
    if (currentStep === 0) {
      return;
    }

    onPrevious();
  };

  const handleNext = () => {
    if (currentStep === totalSteps - 1) {
      return;
    }

    onNext();
  };

  const handleDotClick = (index: number) => {
    onClick(index);
  };

  return (
    <Box
      height={withCarousel ? '24px' : '100%'}
      width={'100%'}
      marginTop={withCarousel ? '40px' : '0'}
      position={withCarousel ? 'relative' : 'absolute'}
      {...restProps}
    >
      {withCarousel && (
        <Box
          position={'absolute'}
          bottom={'0'}
          left={'50%'}
          transform={'translateX(-50%)'}
          display={'flex'}
          alignItems={'center'}
          height={'24px'}
        >
          {Array.from({ length: totalSteps }).map((_, stepNumber) => (
            <Box
              key={stepNumber}
              role={'button'}
              onClick={() => handleDotClick(stepNumber)}
              padding={'12px'}
              flexShrink={0}
            >
              <Dot isVisible={stepNumber === currentStep} />
            </Box>
          ))}
        </Box>
      )}

      {withArrows && (
        <>
          <Box
            position={'absolute'}
            bottom={withCarousel ? '0' : 'calc(50% + 12px)'}
            opacity={currentStep === 0 ? '0.6' : '1'}
            height="100%"
          >
            <ButtonIcon
              onClick={handlePrevious}
              padding="0"
              iconProps={{ type: EIcon.ANGLE, size: ESize.MD, orientation: EPosition.LEFT }}
              aria-label={tAria('prevButton')}
            />
          </Box>

          <Box
            position={'absolute'}
            bottom={withCarousel ? '0' : 'calc(50% + 12px)'}
            right={'0'}
            opacity={currentStep === totalSteps - 1 ? '0.6' : '1'}
            height="100%"
          >
            <ButtonIcon
              onClick={handleNext}
              padding="0"
              iconProps={{ type: EIcon.ANGLE, size: ESize.MD, orientation: EPosition.RIGHT }}
              aria-label={tAria('nextButton')}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
