import { TwitterScript } from '@twitter';
import { useEffect, useRef, useState } from 'react';
import { TWITTER_EVENT } from 'twitter/src/TwitterNextScript';

import { useContextUtil } from '@core/context';
import { Logger } from '@core/logger';

import { Box } from '../layout';

export const Tweet = ({ id }: { id: string }) => {
  const { nonce } = useContextUtil();
  const ref = useRef(null);
  const [isTwitterLoaded, setTwitterLoaded] = useState(false);

  useEffect(() => {
    const addTweet = async () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      await window.twttr.ready();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      await window.twttr.widgets.createTweet(id, ref.current);
    };
    if (window?.twttr && isTwitterLoaded) {
      addTweet().catch((e) => Logger.logError(e));
    }
  }, [isTwitterLoaded]);

  useEffect(() => {
    const handleTwitterLoaded = () => {
      setTwitterLoaded(true);
    };

    window.addEventListener(TWITTER_EVENT, handleTwitterLoaded);

    return () => window.removeEventListener(TWITTER_EVENT, handleTwitterLoaded);
  }, []);

  return (
    <>
      <TwitterScript nonce={nonce} />
      <Box ref={ref}></Box>
    </>
  );
};
