import { MouseEventHandler, TouchEventHandler } from 'react';
import styled, { css, CSSObject } from 'styled-components';

import { BaseBoxProps } from '../layout/interface-layout';

export const ContainerCarouselViews = styled.div(
  ({
    gap,
    onTouchStart: _onTouchStart,
    onTouchMove: _onTouchMove,
    onTouchEnd: _onTouchEnd,
    onMouseDown: _onMouseDown,
    onMouseMove: _onMouseMove,
    onMouseUp: _onMouseUp,
    ...restProps
  }: BaseBoxProps & {
    gap?: BaseBoxProps['padding'];
    onTouchStart?: TouchEventHandler;
    onTouchMove?: TouchEventHandler;
    onTouchEnd?: TouchEventHandler;
    onMouseDown?: MouseEventHandler;
    onMouseMove?: MouseEventHandler;
    onMouseUp?: MouseEventHandler;
  }) => {
    return css`
      display: flex;
      transition: transform 0.2s;
      width: 100%;
      scrollbar-color: transparent transparent !important;

      &::-webkit-scrollbar {
        display: none;
      }

      ${restProps as CSSObject}
      div + div {
        padding-left: ${gap || 0};
      }
    `;
  },
);
