import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';

import { Translate } from '@core/constant';
import { EFilenameBlur, getStaticPathImage, getStaticPathImageBlurData } from '@core/media';
import {
  EActionAttribute,
  EColor,
  EFontWeight,
  EIcon,
  ERouting,
  ESize,
  ETypographyVariant,
} from '@core/type';

import { Icon } from '../icon';
import { Image } from '../image';
import { Box } from '../layout';
import { LinkTranslatedStyledAsLink } from '../link-translated';
import { TextWithLineLimit, Typography } from '../typography';
import { CardMedia } from './CardMedia';
import { StyledCardMediaWrapper } from './StyledCardMediaWrapper';
import { CardArticleChartsProps } from './interface-card';

export const CardArticleCharts: FC<CardArticleChartsProps> = ({
  hasVideo,
  title,
  subtitle,
  image,
  category,
  readingTime,
  headerColor = EColor.SECONDARY,
  linkTranslatedProps,
  tags,
  categoryColor = EColor.SECONDARY,
  withDefaultImage,
  actionAttribute,
  iconColor,
}) => {
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);
  const { t: tBlog } = useTranslation(Translate.page.BLOG_HOME);
  const { t: tAria } = useTranslation(Translate.common.ARIA);

  return (
    <Box as={EActionAttribute.ARTICLE} aria-label={tAria('articleCard')}>
      {(image || withDefaultImage) && (
        <StyledCardMediaWrapper headerColor={headerColor}>
          <LinkTranslatedStyledAsLink {...linkTranslatedProps}>
            {image ? (
              <CardMedia height={208} withVideo={hasVideo} imageProps={image} />
            ) : (
              withDefaultImage && (
                <Box height={'208px'} position={'relative'}>
                  <Image
                    src={getStaticPathImage('card/article-default.png')}
                    blurDataURL={getStaticPathImageBlurData(
                      EFilenameBlur['card/article-default.png'],
                    )}
                    alt={tBlog('defaultImageAlt')}
                    priority
                    style={{
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />
                </Box>
              )
            )}
          </LinkTranslatedStyledAsLink>
        </StyledCardMediaWrapper>
      )}

      <Box marginBottom={'16px'} marginTop={image || withDefaultImage ? '32px' : '0'}>
        <LinkTranslatedStyledAsLink
          keyRoute={ERouting.BLOG_CATEGORY}
          query={{ slug: category?.slug }}
          display="inline-block"
          color={categoryColor}
          fontWeight={EFontWeight.MEDIUM}
        >
          {category?.title}
        </LinkTranslatedStyledAsLink>

        <Typography
          marginLeft="10px"
          display="inline-block"
          color={EColor.SECONDARY}
          variant={ETypographyVariant.BODY2}
        >
          {tProduct('timeLeft.minutesRead', {
            minutes: readingTime?.number,
          })}
        </Typography>
      </Box>

      {title && (
        <TextWithLineLimit as={actionAttribute} margin={'8px 0'}>
          <LinkTranslatedStyledAsLink variant={ETypographyVariant.H4} {...linkTranslatedProps}>
            {title}
          </LinkTranslatedStyledAsLink>
        </TextWithLineLimit>
      )}

      {subtitle && (
        <Box marginTop="8px">
          <TextWithLineLimit lines={4}>
            <LinkTranslatedStyledAsLink
              variant={ETypographyVariant.BODY2}
              isUnderlinedHover={false}
              display="block"
              {...linkTranslatedProps}
            >
              {subtitle}
            </LinkTranslatedStyledAsLink>
          </TextWithLineLimit>
        </Box>
      )}

      <Box marginTop="16px">
        {tags?.length > 0 && (
          <Icon
            type={EIcon.TAG}
            size={ESize.MD}
            display="inline-block"
            verticalAlign="middle"
            marginRight="12px"
            color={iconColor}
          />
        )}
        {tags?.map(({ slug, title, name }) => (
          <Box key={slug} display="inline-block" marginRight="10px" verticalAlign="middle">
            <LinkTranslatedStyledAsLink
              keyRoute={ERouting.BLOG_TAG}
              query={{ slug }}
              variant={ETypographyVariant.BODY3}
              color={categoryColor}
            >
              {title || name}
            </LinkTranslatedStyledAsLink>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
